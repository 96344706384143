.calculator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 94%;
    max-width: 600px;
    margin-top: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .calc-team-selector {
    width: 80%;
    margin-top: 1em;
  }

 .selected-pick {
  margin-top: 10px; /* space between id card and trade card */
  background-color: #c3b30a; /* Different background color */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }

  .trade-board {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width:85%;
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .trade-sides {
    display: flex;
    width: 100%;
  }
  .side {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .side h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .trade-select {
    width: 80%;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
  }
  
  .pick-row {
    margin-bottom: .5em;
  }

  button {
    padding: 10px 20px;
    background-color: #b3ad18;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .button-other {
    padding: 10px 20px;
    margin: 10px 10px 10px 10px;
    background-color: #b3ad18;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .results {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }
  
  .results h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .result {
    margin-bottom: 10px;
    font-size: 1.1em;
  }
  
  .result img {
    width: 15px;
    height: 25px;
    vertical-align: middle;
    margin-left: 10px;
  }
  