.rules {
  padding: 20px;
}

section {
  margin-top: 20px;
  text-align: left;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

h3 {
  margin-top: 10px;
}

p {
  text-align: left;
}
