#schedule {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
}

.week {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.week-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.week-header h3 {
  margin: 0;
  font-size: 1.5em;
}

.week-date {
  font-size: 0.9em;
  color: #555;
  text-align: right;
}

.matchups {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.matchup {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 10px 0;
  width: 80%;
  text-align: center;
}

.teams {
  font-size: 1.2em;
  font-weight: bold;
}

.owners {
  font-size: 1em;
  color: #777;
}
