.draft-picks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  width: 100%
}

.draftnav {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #d1ce1d; /* Golden color */
  padding: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 0; /* Align it directly under the header */
  box-sizing: border-box; /* Ensure the nav fits within the width */
}

.draftnav button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #d1ce1d; /* Golden color */
  border-radius: 5px;
  transition: background-color 0.3s ease;
  color: white;
  border: none;
  font-size: 1em;
}

.draftnav button.active {
  background-color: #b3ad18;
}

.teams-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 89%;
  max-width: 600px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  padding: 10px 10px 0px 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.block-view,
.trade-view-parent {
  width: 89%;
  max-width: 600px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px 10px 0px 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.block-card {
  background-color: #fff;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.trade-team {
  margin-bottom: 5px;
}

.custom-select {
  position: relative;
  font-family: Arial;
  margin-top: 10px;
  width: 60%;
}

.team-select {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}

.select-selected {
  background-color: #d1ce1d; /* Golden color */
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: left;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.select-items {
  position: absolute;
  background-color: #d1ce1d; /* Golden color */
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  border-radius: 5px;
  /* display: none; */
  overflow: hidden;
}

.select-items div, .select-selected {
  color: #ffffff;
  padding: 8px 16px;
  cursor: pointer;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.draft-picks-table {
  width: 94%;
  max-width: 800px; /* Increase the max-width for larger screens */
  border-collapse: collapse;
  margin: 20px;
  border-radius: 8px; /* Add rounded corners */
  overflow: hidden; /* Ensure border radius affects children */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Add a shadow for depth */
}

.team-picks-table {
  width: 94%;
  max-width: 400px; /* Increase the max-width for larger screens */
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 8px; /* Add rounded corners */
  overflow: hidden; /* Ensure border radius affects children */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Add a shadow for depth */
}

.draft-picks-table th,
.draft-picks-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.draft-picks-table th {
  background-color: #f9f9f9;
  font-weight: bold;
}

.even-round {
  background-color: #e8f4ff;
}

.odd-round {
  background-color: #fff;
}

.trade-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.trades-view h3 {
  margin-bottom: 10px;
  font-size: .2em;
  align-items: center
}

.trade-card-content {
  display: flex;
  flex-direction: column;
}

.trade-details {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.trades-view {
  display: flex;
  flex-direction: column;
}

.trade-row {
  display: flex;
  align-items: stretch; /* Ensures both elements stretch to the full height of the row */
  margin-bottom: 20px;
}

.trade-id-card {
  flex: 0 0 100px; /* width of the id card column */
  margin-right: 20px; /* space between id card and trade card */
  background-color: #c3b30a; /* Different background color */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.trade-card {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.trade-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.trade-team {
  margin-bottom: 5px;
}

.trade-card-date {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 10px;
}

.overall {
  font-weight: bold;
  color: #333;
}

.number {
  font-weight: bold;
}

.team {
  color: #555;
}

.total-trades-meter {
  margin-bottom: 20px;
  text-align: center;
}

.meter {
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 5px;
}

.meter-fill {
  height: 100%;
  background-color: #76c7c0;
  transition: width 0.5s ease;
}


.ninth-row {
  border-bottom: 3px solid red;
}

/* @media (max-width: 600px) {
  .draft-picks-table {
    width: 94%; /* Ensure full width on smaller screens 
  }

  .draft-picks-table th,
  .draft-picks-table td {
    padding: 8px;
  }
}

*/