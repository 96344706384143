.champion-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.champion-box {
  background-color: #FFD700;
  border: 2px solid #B8860B;
  border-radius: 10px;
  width: 300px;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

.champion-name {
  font-size: 1.5em;
  font-weight: bold;
}

.champion-year {
  font-size: 1.2em;
  margin-top: 10px;
}

.champion-matchup {
  font-size: 1em;
  margin-top: 5px;
}
