#leadership {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.current-commissioner {
  text-align: center;
  margin-bottom: 40px;
}

.commissioner-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.title {
  font-size: 2em;
  margin: 10px 0;
}

.name {
  font-size: 1.5em;
  color: #555;
}

.past-commissioners {
  width: 80%;
  max-width: 600px;
}

.past-commissioners h3 {
  text-align: center;
  margin-bottom: 20px;
}

.leadership-table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

th {
  background-color: #f9f9f9;
  font-weight: bold;
}
