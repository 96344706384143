.owners-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.owner-box {
  display: flex;
  width: 85%; /* Adjust to control the width of each box */
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.owner-image {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 50%;
}

.owner-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.owner-details div {
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
}
