@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f8f8;
  color: #333;
  text-align: center;
}

header {
  display: flex;
  align-items: center;
  background-color: #3a3b3a;
  color: white;
  padding: 10px 10px 0px 0px;
  font-weight: 800;
  font-size: 3.5em;
  text-shadow: .07em .07em .07em black;
}

.page-header {
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 2em;
}

.navbar {
  display: flex;
  justify-content: center;
  background-color: #848f85;
  align-items: center; /* Center items vertically */
  padding: 5px 5px;
  font-family: 'Roboto', sans-serif;
  font-size: .75em;
}

.navbar a {
  margin-left: .6em;
  text-decoration: none;
  text-shadow: .2em .2em .2em black;
  color: white;
  font-weight: 700;
}

.navbar a:hover {
  color: white;
  text-shadow: .4em .4em .4em black;
}

.looseygoosey {
  height: 3.2em;
  margin-right: .1em;
}

/**section {
  margin: 20px;
}**/

.countdown h2 {
  color:#ffffff;
  margin-left: .1em;
  text-shadow: .1em .1em .1em black;
}

#countdown {
  font-size: 1.5em;
  font-weight: 700;
  text-shadow: .1em .1em .1em black;
  color:#d1ce1d
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8f2a2a;
  /**padding: .1em;**/
  font-family: 'Roboto', sans-serif;
}

.ball {
  position: absolute;
  height: 1.5em;
}

.ball:first-of-type {
  left: 0;
}

.ball:last-of-type {
  right: 0;
}

footer {
  background-color: #657068;
  color: white;
  padding: 10px 0;
  width: 100%;
  position: relative;
  z-index: 1000;
  text-align: center;
}
