.news {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.news-article {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.news-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.news-meta {
  color: #888;
  font-size: 0.9em;
  margin-bottom: 20px;
}

.news-content {
  margin-bottom: 15px;
  line-height: 1.6;
}